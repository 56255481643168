import { type User } from '~/models/User';

export type UserState = {
    user: User | null;
}

export const defaultStateData = (): UserState => ({
    user: null,
});

export const DefaultState = (): UserState => defaultStateData();

export type State = ReturnType<typeof DefaultState>
