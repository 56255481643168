import { SET_USER } from './-mutation-types';
import { useUsersStore } from '~/stores/users';
import { type User } from '~/models/User';

const Mutations = {
    [SET_USER](payload: User | null) {
        const usersStore = useUsersStore();
        usersStore.user = payload;
    },
};

export default Mutations;
